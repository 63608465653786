import { ReactElement } from "react";

export function SecondTutorialSlideImage({
  color,
  light,
}: {
  color: string;
  light: string;
}): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 212 205"
    >
      <defs>
        <path id="a" d="M0 0h222v222H0z" />
      </defs>
      <g transform="translate(-1 -6)" fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <g transform="translate(3 8)">
            <circle
              stroke={color}
              strokeWidth="3"
              cx="167.5"
              cy="191.5"
              r="6.5"
            />
            <circle stroke={color} strokeWidth="3" cx="6.5" cy="95.5" r="6.5" />
            <circle
              stroke={color}
              strokeWidth="3"
              cx="173.5"
              cy="8.5"
              r="8.5"
            />
            <circle
              fill={color}
              fillRule="nonzero"
              opacity=".1"
              cx="108"
              cy="103"
              r="65"
            />
            <g transform="translate(109 18)" fillRule="nonzero">
              <circle
                stroke={color}
                strokeWidth="3"
                fill={light}
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="rotate(-45 36 36)"
                cx="36"
                cy="36"
                r="25.5"
              />
              <path
                d="M39.6 46.6c2.1 0 3.9-.5 5-1.1L43.4 43c-.8.5-2.2.8-3.7.8-2.7 0-5-1.4-5.9-4.7h7.7v-1.7h-8V36v-1.5h8v-1.7h-7.7c.9-3.3 3.2-4.7 5.9-4.7 1.5 0 2.9.3 3.7.8l1.2-2.3c-1.1-.6-3-1-5-1a8.6 8.6 0 00-8.9 7.2h-3.1v1.7h2.9a14.4 14.4 0 000 3h-3v1.7h3.2c1 5 4.6 7.4 8.9 7.4z"
                fill={color}
              />
            </g>
            <path
              d="M190.5 128a86.5 86.5 0 00-11.6-73.3M132.6 20.7A86.5 86.5 0 0024 80.7M57.9 174.4a86.1 86.1 0 0082.1 9.3"
              stroke={color}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="7,10"
            />
            <path
              stroke={color}
              strokeWidth="3"
              d="M198.5 48v13M205 54.5h-13M41.5 17v17M50 25.5H33M38.5 186v17M47 194.5H30M201.5 137v17M210 145.5h-17"
            />
          </g>
        </g>
        <g mask="url(#b)">
          <path
            d="M100 84.2l4-2.4a6 6 0 018.2 2.5v.1c1.7 3 .6 7-2.3 8.6l-9.8 6h-.1"
            fill="#fff"
          />
          <path
            d="M100 84.2l4-2.4a6 6 0 018.2 2.5v.1c1.7 3 .6 7-2.3 8.6l-9.8 6h-.1"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.7 181l9.1-12.7A60 60 0 0079 157l-32.7-37.6-.9.4c-1.2.5-1.9.2-2.3-.3a2 2 0 01-.5-1.7l3.5-28.5.2-1.6a5.8 5.8 0 00-11.4-1.3L23.2 124s-2 12.2-2.3 14.8c-.3 3.6.8 7.3.8 7.3L11 159.9"
            fill="#fff"
          />
          <path
            d="M47.7 181l9.1-12.7A60 60 0 0079 157l-32.7-37.6-.9.4c-1.2.5-1.9.2-2.3-.3a2 2 0 01-.5-1.7l3.5-28.5.2-1.6a5.8 5.8 0 00-11.4-1.3s0 0 0 0L23.2 124s-2 12.2-2.3 14.8c-.3 3.6.8 7.3.8 7.3L11 159.9"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M100 101.5V68c0-1.2-1-2.1-2.1-2.1H49C48 66 47 67 47 68.1V156c0 1.2 1 2.1 2.1 2.1H98c1.2 0 2.1-1 2.1-2.1v-12.4"
            fill="#fff"
          />
          <path
            d="M100 101.5V68c0-1.2-1-2.1-2.1-2.1H49C48 66 47 67 47 68.1V156c0 1.2 1 2.1 2.1 2.1H98c1.2 0 2.1-1 2.1-2.1v-12.4"
            stroke={color}
            strokeWidth="3"
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g stroke={color} strokeWidth="3" fill={light} fillRule="nonzero">
            <path d="M64.4 98l-12 17.3c-.7 1-.5 2.2.5 3l2.5 1.7h.3a5 5 0 016.4 4.5l.1.4 2.5 1.7a2 2 0 003-.5l12-17.4c.6-1 .4-2.2-.6-3l-2.5-1.7h-.3 0a5 5 0 01-6.5-4.5V99l-2.5-1.7a2 2 0 00-3 .5z" />
            <path d="M73.4 98l-12 17.3c-.7 1-.5 2.2.5 3l2.5 1.7h.3a5 5 0 016.4 4.5l.1.4 2.5 1.7a2 2 0 003-.5l12-17.4c.6-1 .4-2.2-.6-3l-2.5-1.7h-.3 0a5 5 0 01-6.5-4.5V99l-2.5-1.7a2 2 0 00-3 .5z" />
          </g>
          <path fill="#fff" d="M100.5 115v-1" />
          <path
            stroke="#2b619e"
            strokeWidth="6.7"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M100.5 115v-1"
          />
          <path fill="#fff" d="M100.5 132v-2" />
          <path
            stroke="#2b619e"
            strokeWidth="6.7"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M100.5 132v-2"
          />
          <path
            d="M98.4 131.6l.8-.3c2.8-1 5.9.5 6.8 3.3l.7 1.8c1 2.8-.5 6-3.3 6.9l-1 .3a6.3 6.3 0 01-8-3.8v-.1a6.3 6.3 0 013.8-8l.2-.1"
            fill="#fff"
          />
          <path
            d="M98.4 131.6l.8-.3c2.8-1 5.9.5 6.8 3.3l.7 1.8c1 2.8-.5 6-3.3 6.9l-1 .3a6.3 6.3 0 01-8-3.8v-.1a6.3 6.3 0 013.8-8l.2-.1z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M99.2 115.5l.5-.2c2.5-1 5.2.5 6 3.1l1 3c.9 2.7-.4 5.6-2.9 6.5l-2 .8c-.9.3-1.7.4-2.6.2-2-.2-3.7-1.7-4.4-3.9l-.5-1.4c-1-3 .5-6.5 3.4-7.5l1.5-.6"
            fill="#fff"
          />
          <path
            d="M99.2 115.5l.5-.2c2.5-1 5.2.5 6 3.1l1 3c.9 2.7-.4 5.6-2.9 6.5l-2 .8c-.9.3-1.7.4-2.6.2-2-.2-3.7-1.7-4.4-3.9l-.5-1.4c-1-3 .5-6.5 3.4-7.5l1.5-.6z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M98.4 101.4l.3-.1c2.5-1 5.2.5 6 3.1l1 3c.9 2.7-.4 5.6-2.9 6.5l-2 .8a5 5 0 01-2.4.3c-2-.3-3.9-1.7-4.6-4l-.5-1.4a6 6 0 013.4-7.5l1.7-.7"
            fill="#fff"
          />
          <path
            d="M98.4 101.4l.3-.1c2.5-1 5.2.5 6 3.1l1 3c.9 2.7-.4 5.6-2.9 6.5l-2 .8a5 5 0 01-2.4.3c-2-.3-3.9-1.7-4.6-4l-.5-1.4a6 6 0 013.4-7.5l1.7-.7z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path fill="#fff" d="M22 146l2 5" />
          <path
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M22 146l2 5M47 150.5h53M100 73.5H47M77 154.5h-6"
          />
        </g>
        <g mask="url(#b)">
          <path
            d="M176.9 183H128a8.1 8.1 0 01-8.1-8.1V128c0-4.5 3.6-8.1 8.1-8.1H177c4.5 0 8.1 3.6 8.1 8.1V175c0 4.5-3.6 8.1-8.1 8.1"
            fill="#fff"
          />
          <path
            d="M176.9 183H128a8.1 8.1 0 01-8.1-8.1V128c0-4.5 3.6-8.1 8.1-8.1H177c4.5 0 8.1 3.6 8.1 8.1V175c0 4.5-3.6 8.1-8.1 8.1z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M129.5 128c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2"
            fill={light}
          />
          <path
            d="M129.5 128h0c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M145.5 128c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2"
            fill={light}
          />
          <path
            d="M145.5 128h0c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M159.5 128c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2"
            fill={light}
          />
          <path
            d="M159.5 128h0c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M175.5 128c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2"
            fill={light}
          />
          <path
            d="M175.5 128h0c-1.4 0-2.5-1-2.5-2.2v-7.6c0-1.2 1.1-2.2 2.5-2.2s2.5 1 2.5 2.2v7.6c0 1.2-1.1 2.2-2.5 2.2z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M173.7 177h-43.4c-3 0-5.3-2.3-5.3-5.2v-28.6c0-2.9 2.4-5.2 5.3-5.2h43.4c3 0 5.3 2.3 5.3 5.2v28.6c0 2.9-2.4 5.2-5.3 5.2"
            fill="#fff"
          />
          <path
            d="M173.7 177h-43.4c-3 0-5.3-2.3-5.3-5.2v-28.6c0-2.9 2.4-5.2 5.3-5.2h43.4c3 0 5.3 2.3 5.3 5.2v28.6c0 2.9-2.4 5.2-5.3 5.2z"
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path fill={color} d="M126 164h39v-12h-39z" fillOpacity="0.8" />
          <path
            stroke={color}
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M166 138v39M125 165h54M125 151h54M153 138v39M138 138v39M120 133h64"
          />
        </g>
      </g>
    </svg>
  );
}
