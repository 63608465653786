import { css } from "@emotion/react";
import { Spinner } from "common/Spinner";
import {
  forwardRef,
  ForwardRefExoticComponent,
  MouseEvent,
  ReactNode,
  RefAttributes,
} from "react";

export interface MobeaButtonProps {
  id?: string;
  type?: "primary" | "secondary" | "tertiary";
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

/**
 * Abstract implementation with generic styling
 * Should never be imported directly
 */
const MobeaButtonAbstract = forwardRef<HTMLButtonElement, MobeaButtonProps>(
  (
    {
      id,
      children,
      type,
      className = "",
      disabled = false,
      loading = false,
      onClick,
    },
    ref
  ) => {
    const clickHandler = (e: MouseEvent) => {
      e.preventDefault();

      e.stopPropagation();

      onClick?.();
    };

    return (
      <button
        id={id}
        ref={ref}
        className={`mobea__button mobea__button__${type} ${className} ${
          loading ? "mobea__button__loading" : ""
        }`}
        disabled={disabled}
        onClick={onClick && clickHandler}
        css={[
          css`
            min-height: 48px;
            width: 100%;
            border-radius: 2px;
            border: 2px solid transparent;
            margin-top: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            overflow: hidden;
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.5rem;
            text-align: center;
            transition: background-color 300ms ease-in,
              border-color 300ms ease-in;

            .font-daxlinepro & {
              padding-top: 12px;
              padding-bottom: 8px;
            }

            &::first-letter {
              text-transform: uppercase;
            }
            &:disabled {
              color: var(--color-gray-100);
            }
          `,
          loading
            ? {
                "&.mobea__button__primary .mobea__spinner > div": {
                  borderColor: "white transparent transparent transparent",
                },
              }
            : null,
        ]}
      >
        {loading && <Spinner style="button" size={30} />}

        <span
          className="mobea__button__content"
          css={{
            // this is a hack for iOS where the text is otherwise shifted down
            // https://stackoverflow.com/a/47818418/289827
            "@supports (-webkit-touch-callout: none)": {
              position: "relative",
              top: -2,
            },
          }}
        >
          {children}
        </span>
      </button>
    );
  }
);

MobeaButtonAbstract.displayName = "MobeaButton";

const styleEA = {
  primary: css`
    background-color: var(--color-primary);
    color: var(--color-text-inverted);
    border-color: var(--color-primary);

    &:disabled {
      background-color: var(--color-gray-100);
      border-color: var(--color-gray-100);
      color: white;
    }
  `,
  secondary: css`
    background-color: transparent;
    color: var(--color-primary);
    border-color: currentColor;
  `,
  tertiary: css`
    background-color: transparent;
    color: var(--color-primary);
    border: 2px solid transparent;
  `,
};

const MobeaButtonEA = forwardRef<HTMLButtonElement, MobeaButtonProps>(
  ({ type = "primary", ...props }, ref) => {
    return (
      <MobeaButtonAbstract
        ref={ref}
        type={type}
        {...props}
        css={[styleEA[type]]}
      />
    );
  }
);

MobeaButtonEA.displayName = "MobeaButtonEA";

const styleMoveasy = {
  base: css`
    border-radius: 4px;
    font-size: 1rem;
  `,
  primary: css`
    background-color: var(--color-primary);
    color: var(--color-text-inverted);

    &:disabled {
      background-color: var(--color-gray-100);
      border-color: var(--color-gray-100);
      color: white;
    }
  `,
  secondary: css`
    background-color: white;
    color: var(--color-primary);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  `,
  tertiary: css`
    background-color: white;
    color: var(--color-primary);
    border: 2px solid white;
  `,
};

const MobeaButtonMoveasy = forwardRef<HTMLButtonElement, MobeaButtonProps>(
  ({ type = "primary", ...props }, ref) => {
    return (
      <MobeaButtonAbstract
        ref={ref}
        type={type}
        {...props}
        css={[styleMoveasy.base, styleMoveasy[type]]}
      />
    );
  }
);

MobeaButtonMoveasy.displayName = "MobeaButtonMoveasy";

let buttonComponent: ForwardRefExoticComponent<
  MobeaButtonProps & RefAttributes<HTMLButtonElement>
>;

                        
buttonComponent = MobeaButtonEA;
          
                                                      
                                     
          

export { buttonComponent as MobeaButton };
