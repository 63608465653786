import { GlobeIcon } from "icons/GlobeIcon";
import { TickIcon } from "icons/TickIcon";
import { UpIcon } from "icons/UpIcon";
import { CSSProperties, ReactElement, useState } from "react";
import "./LanguageSelector.scss";

export type SelectorInputLanguage = {
  code: string;
  title: string;
};

export interface LanguageSelectorProps {
  languages: SelectorInputLanguage[];
  currentLanguageCode: string;
  onChange: (languageCode: string) => void;
}

export function LanguageSelector({
  languages,
  currentLanguageCode,
  onChange,
}: LanguageSelectorProps): ReactElement {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const closeSelector = () => setExpanded(false);

  // 24 is option height, 16 is margin, 16 is wrapper top padding
  const optionsHeight = 16 + languages.length * (24 + 16);

  const optionsStyle: CSSProperties = {
    bottom: expanded ? "100%" : `-${optionsHeight}px`,
  };

  const currentLanguage =
    languages.find(({ code }) => code === currentLanguageCode) || languages[0];

  const toLanguageOption = ({ code, title }) => (
    <li
      key={code}
      className="mobea__language__option"
      onClick={() => onChange(code)}
    >
      <span>
        {title}
        {code === currentLanguageCode && <TickIcon width="12" height="16" />}
      </span>
    </li>
  );

  return (
    <>
      <div
        className={`mobea__overlay ${expanded ? "mobea__expanded" : ""}`}
        onClick={closeSelector}
      >
        &nbsp;
      </div>
      <section
        className={`mobea__language ${expanded ? "mobea__expanded" : ""}`}
        onClick={toggleExpanded}
      >
        <div className="mobea__language__current">
          <span className="mobea__language__current__globe-icon">
            <GlobeIcon />{" "}
          </span>
          <span>{currentLanguage ? currentLanguage.title : ""}</span>
          <span className="mobea__language__current__arrow-icon">
            {" "}
            <UpIcon />
          </span>
        </div>
        <ul
          className={`mobea__language__options ${
            expanded ? "mobea__expanded" : ""
          }`}
          style={optionsStyle}
        >
          {languages
            .filter(({ code }) => code != currentLanguageCode)
            .map(toLanguageOption)}
        </ul>
      </section>
    </>
  );
}
