import { StandardErrorDialog } from "common/errors/StandardErrorDialog";
import { MobeaButton } from "common/forms";
import { useUpdatedCustomer } from "common/hooks/useUpdatedCustomer";
import { NestedPageFull } from "common/page/NestedPageFull";
import { Spinner } from "common/Spinner";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  ApiErrors,
  formatToServiceDate,
  validateEmail,
  validateIban,
} from "utils";
import { ProfileUpdateSuccessDialog } from "./dialogs/ProfileUpdateSuccessDialog";
import { ProfileForm } from "./form/ProfileForm";
import { useUpdateProfile } from "./hooks/useUpdateProfile";
import "./ProfilePage.scss";

export function ProfilePage(): ReactElement {
  const { t } = useTranslation();

  const loading = useUpdatedCustomer();

  const user = useSelector((state) => state.user);

  const {
    customerId,
    name,
    surname,
    phonePrefix,
    phoneNumber,
    dateOfBirth,
    language,
  } = user;

  const [email, setEmail] = useState("");

  const [iban, setIban] = useState("");

  const [result, updating, updateProfile, reset] = useUpdateProfile();

  const emailValid = validateEmail(email.toString());

  const ibanValid = validateIban(iban).valid;

  const formattedDateOfBirth = dateOfBirth
    ? formatToServiceDate(new Date(dateOfBirth))
    : "";

  const changed =
    formattedDateOfBirth !== user.dateOfBirth ||
    email != user.email ||
    iban != user.iban;

  const saveEnabled = emailValid && ibanValid && changed;

  useEffect(() => {
    if (user) {
      const { email, iban } = user;
      setEmail(email);
      setIban(iban);
    }
  }, [user]);

  const doProfileUpdate = () => {
    updateProfile(customerId, {
      email,
      iban,
    });
  };

  return (
    <NestedPageFull pageClassName="mobea-profile" title={t("settings.profile")}>
      {loading && <Spinner />}
      {!loading && (
        <>
          <ProfileForm
            dateOfBirth={dateOfBirth}
            email={email}
            iban={iban}
            language={language}
            nameAndSurname={`${name} ${surname}`}
            showName
            phoneNumber={phoneNumber}
            phonePrefix={phonePrefix}
            onEmailChange={setEmail}
            onIbanChange={setIban}
          />
          <section className="mobea-profile__empty-space"></section>
          <section className="mobea-profile__button">
            <MobeaButton
              disabled={!saveEnabled || updating}
              onClick={doProfileUpdate}
              loading={updating}
            >
              {updating ? t("shared.saving") : t("shared.save")}
            </MobeaButton>
          </section>
          {result === "success" && (
            <ProfileUpdateSuccessDialog onConfirm={reset} />
          )}
          {result && result in ApiErrors && (
            <StandardErrorDialog onConfirm={reset} />
          )}
        </>
      )}
    </NestedPageFull>
  );
}
