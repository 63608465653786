import { MobeaModal } from "common/modal";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ApiErrors } from "utils";

export type RequestPhoneVerificationErrorType =
  | "invalid"
  | "external"
  | ApiErrors.CLIENT_NOT_ACTIVE;

interface Props {
  errorType: RequestPhoneVerificationErrorType;
  tryAgain(): void;
  contactUs(): void;
}

export function RequestPhoneVerificationErrorDialog({
  errorType,
  tryAgain,
  contactUs,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {errorType === "invalid" && (
        <MobeaModal
          confirmText={t("verification.try_again")}
          cancelText={t("contact_us.need_help")}
          cancelButtonType="secondary"
          title={t("shared.sorry_but")}
          type="error"
          onConfirm={tryAgain}
          onCancel={contactUs}
        >
          <p>{t("verification.error_phone_no_plan")}</p>
        </MobeaModal>
      )}

      {errorType === "external" && (
        <MobeaModal
          confirmText={t("verification.try_again")}
          title={t("shared.sorry_but")}
          type="error"
          onConfirm={tryAgain}
        >
          <p>{t("verification.error_external")}</p>
        </MobeaModal>
      )}

      {errorType === ApiErrors.CLIENT_NOT_ACTIVE && (
        <MobeaModal
          title={t("shared.sorry_but")}
          confirmText={t("contact_us.call_us")}
          cancelText={t("shared.ok")}
          cancelButtonType="secondary"
          type="error"
          onConfirm={contactUs}
          onCancel={tryAgain}
        >
          <p>{t("verification.error_client_inactive_text")}</p>
        </MobeaModal>
      )}
    </>
  );
}
