import { ADMIN_API_BASE } from "utils/constants";
import { Auth } from "./authTokenStore";
import { fetchTimeoutIncident } from "./fetchTimeout";

export interface RequestPhoneVerificationResponse {
  success?: string;
  error?: string;
  error_code?: string;
  error_type?: string;
  error_description?: string;
}

export function requestCode(prefix: string, number: string, clientId: number) {
  return fetchTimeoutIncident<RequestPhoneVerificationResponse>(
    `${ADMIN_API_BASE}resend-sms`,
    {
      method: "POST",
      headers: new window.Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        phone_prefix: prefix,
        phone_number: number,
        client: clientId,
      }),
    }
  );
}

export function confirmCode(token: string) {
  return Auth.requestTokenWithCode(token);
}
