import { CookiesBanner } from "common/cookies/Cookies";
import { GlobalWarning } from "common/global/Warnings";
import { HistoryEaPage } from "pages/history/HistoryEaPage";
import { HomePage } from "pages/home/HomePage";
import { ProfilePage } from "pages/profile/ProfilePage";
import { SettingsPage } from "pages/settings/SettingsPage";
import { TutorialPage } from "pages/tutorial/TutorialPage";
import { ConfirmPhoneVerificationPage } from "pages/userVerification/ConfirmPhoneVerificationPage";
import { RequestPhoneVerificationPage } from "pages/userVerification/RequestPhoneVerificationPage";
import { WelcomePage } from "pages/welcome/WelcomePage";
import { Route } from "react-router-dom";
import { Routes } from "utils";

const RoutesEaSecured = [
  <Route key={Routes.Welcome} path={Routes.Welcome}>
    <WelcomePage />
  </Route>,
  <Route key={Routes.Tutorial} path={Routes.Tutorial}>
    <TutorialPage />
  </Route>,
  <Route key={Routes.History} path={Routes.History} exact>
    <HistoryEaPage />
    <GlobalWarning />
  </Route>,
  <Route key={Routes.Profile} path={Routes.Profile}>
    <ProfilePage />
  </Route>,
  <Route key={Routes.Settings} path={Routes.Settings} exact>
    <SettingsPage />
    <GlobalWarning />
  </Route>,

  <Route key={Routes.Home} path={Routes.Home}>
    <HomePage />
    <CookiesBanner />
    <GlobalWarning />
  </Route>,
];

export const RoutesEaPublic = [
  <Route
    key={Routes.RequestPhoneVerification}
    path={Routes.RequestPhoneVerification}
  >
    <RequestPhoneVerificationPage />
  </Route>,
  <Route
    key={Routes.ConfirmPhoneVerification}
    path={Routes.ConfirmPhoneVerification}
  >
    <ConfirmPhoneVerificationPage />
  </Route>,
];

export const RoutesEaAll = RoutesEaPublic.concat(RoutesEaSecured);
