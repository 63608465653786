import { MobeaButton } from "common/forms";
import { useClientLogo } from "common/hooks/useClientLogo";
import { useContactUsModal } from "common/modal";
import { FullScreenPage } from "common/page/FullScreenPage";
import { usePhoneNumbersValidation } from "common/phoneNumberPicker";
import { PhoneNumberPicker } from "common/phoneNumberPicker/PhoneNumberPicker";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { requestCode } from "services/verificationService";
import { verificationRequestedAction } from "state/actions";
import {
  ApiErrors,
  MobeaError,
  pushLocation,
  removeSpaces,
  Routes,
} from "utils";
import { getConfig } from "utils/configure";
import {
  RequestPhoneVerificationErrorDialog,
  RequestPhoneVerificationErrorType,
} from "./dialogs/RequestPhoneVerificationErrorDialog";
import "./RequestPhoneVerificationPage.scss";

interface RequestPhoneVerificationError extends MobeaError {
  type: RequestPhoneVerificationErrorType;
}

export function RequestPhoneVerificationPage(): ReactElement {
  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [
    prefix,
    localNumberFormatted,
    valid,
    onPrefixChange,
    updatePhoneNumber,
    reset,
  ] = usePhoneNumbersValidation();

  const [pendingRequest, setPendingRequest] = useState(false);

  const [requestError, setRequestError] =
    useState<RequestPhoneVerificationError | null>(null);

  const { clientId } = getConfig();

  const clientLogo = useClientLogo();

  const tryAgain = () => {
    batch(() => {
      setRequestError(null);
      reset();
    });
  };

  const { show: showContactUs } = useContactUsModal({
    onClose: tryAgain,
  });

  const verifyPhoneNumber = () => {
    setPendingRequest(true);

    requestCode(prefix.code, removeSpaces(localNumberFormatted), clientId)
      .then((response) => {
        setPendingRequest(false);

        if (response.success) {
          dispatch(verificationRequestedAction());

          pushLocation(history, Routes.ConfirmPhoneVerification);
        } else if (
          response.error &&
          response.error_code === ApiErrors.CLIENT_NOT_ACTIVE
        ) {
          setRequestError({
            type: response.error_code,
            details: response.error,
          });
        } else {
          setRequestError({
            type: "invalid",
            details: response.error,
          });
        }
      })
      .catch((error) => {
        console.error(error);

        batch(() => {
          setPendingRequest(false);
          setRequestError({
            type: "external",
            details: error,
          });
        });
      });
  };

  const submitDisabled = !valid || pendingRequest;

  return (
    <FullScreenPage
      pageClassName="mobea-request-pin"
      padding={{
        left: 32,
        right: 32,
      }}
      css={{
        "> section": {
          justifyContent: "space-evenly",
        },
      }}
    >
      <header className="mobea-request-pin__header">{clientLogo}</header>
      <section className="mobea-request-pin__content">
        <h1 className="mobea-request-pin__content__headline">
          {t("verification.phone_verification")}
        </h1>
        <p className="mobea-request-pin__content__text">
          {t("verification.enter_phone_number_text")}
        </p>
        <PhoneNumberPicker
          prefix={prefix}
          localNumberFormatted={localNumberFormatted}
          valid={valid}
          onPrefixChange={onPrefixChange}
          onPhoneNumberChange={updatePhoneNumber}
          onSubmit={verifyPhoneNumber}
        />
        <MobeaButton disabled={submitDisabled} onClick={verifyPhoneNumber}>
          {pendingRequest && t("verification.checking")}
          {!pendingRequest && t("verification.verify_my_phone_number")}
        </MobeaButton>
      </section>
      {requestError && (
        <RequestPhoneVerificationErrorDialog
          errorType={requestError.type}
          tryAgain={tryAgain}
          contactUs={showContactUs}
        />
      )}
    </FullScreenPage>
  );
}
