import { ReactElement } from "react";

export function ThirdTutorialSlideImage({
  color,
  light,
}: {
  color: string;
  light: string;
}): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 207 209"
    >
      <defs>
        <path id="a" d="M0 0h222v222H0z" />
      </defs>
      <g transform="translate(-15)" fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <g transform="translate(17 2)">
            <circle
              stroke={color}
              strokeWidth="3"
              cx="6.5"
              cy="149.5"
              r="6.5"
            />
            <circle
              stroke={color}
              strokeWidth="3"
              cx="113.5"
              cy="6.5"
              r="6.5"
            />
            <circle
              stroke={color}
              strokeWidth="3"
              cx="176.5"
              cy="181.5"
              r="8.5"
            />
            <circle
              fill={color}
              fillRule="nonzero"
              opacity=".1"
              cx="94"
              cy="109"
              r="65"
            />
            <path
              stroke={color}
              strokeWidth="3"
              d="M196.5 53v17M205 61.5h-17M13.5 32v13M20 38.5H7M33.5 190v17M42 198.5H25"
            />
          </g>
        </g>
        <g fillRule="nonzero" mask="url(#b)">
          <path
            d="M176.4 150h-27.9a8.5 8.5 0 01-8.5-8.4v-38.7c0-2.4 3.6-4.9 9.4-4.9h26.1c5.5 0 9.4 2.5 9.4 5v38.6c0 4.6-3.8 8.4-8.5 8.4z"
            fill={light}
          />
          <path
            d="M176.4 150.9h-27.9a9.4 9.4 0 01-9.3-9.3v-38.7c0-2.8 3.5-5.7 10.2-5.7h26.1c5.6 0 10.3 2.5 10.3 5.7v38.7c0 5.1-4.2 9.3-9.4 9.3zm-26-51c-5.2 0-8.5 2-8.5 4v36.7c0 4.2 3.4 7.6 7.7 7.6h25.8c4.2 0 7.6-3.4 7.6-7.6v-36.7c0-1.7-3.2-4-8.5-4h-24z"
            fill={color}
          />
          <path
            d="M178 132h-31a3 3 0 01-3-3v-18a3 3 0 013-3h31a3 3 0 013 3v18a3 3 0 01-3 3zm-29.7-21c-.7 0-1.3.5-1.3 1.2v15.6c0 .7.6 1.2 1.3 1.2h28.4c.7 0 1.3-.5 1.3-1.2v-15.6c0-.7-.6-1.2-1.3-1.2h-28.4z"
            fill={color}
          />
          <path
            d="M148.3 111c-.7 0-1.3.5-1.3 1.2v15.6c0 .7.6 1.2 1.3 1.2h28.4c.7 0 1.3-.5 1.3-1.2v-15.6c0-.7-.6-1.2-1.3-1.2h-28.4z"
            fill="#fff"
          />
          <path
            d="M181.1 87a1 1 0 01-.7-.3l-2.7-3c-1.8-2-4.1-3.1-6.5-3.1h-16.4c-2.4 0-4.7 1-6.5 3l-2.7 3.1a1 1 0 01-1.3.1.8.8 0 01-.1-1l2.8-3.2c2-2.3 4.9-3.6 7.8-3.6h16.4c3 0 5.7 1.3 7.8 3.6l2.8 3.1c.3.3.3.9-.1 1.1-.2.2-.4.2-.6.2z"
            stroke={color}
            fill={color}
          />
          <path
            d="M159.1 99a1 1 0 01-.9-.7L154 80c0-.4.2-.9.7-1 .5 0 2 .2 2 .7L161 98c0 .4-1.2.9-1.7 1h-.2zM166.8 99h-.2c-.4-.2-1.7-.6-1.6-1l4.4-18.3c.1-.5 1.5-.8 2-.7.4.2.7.6.6 1.1l-4.4 18.3c-.1.3-.4.6-.8.6z"
            fill={color}
          />
          <path
            d="M173 98H152V96c0-.7.5-1.2 1.2-1.2h18.7c.7 0 1.2.5 1.2 1.2V98z"
            fill="#fff"
          />
          <path
            d="M174 99h-23c-.6 0-1-.6-1-1.3v-3.5c0-1.2.8-2.2 1.7-2.2h21.6c1 0 1.7 1 1.7 2.2v3.5c0 .7-.4 1.3-1 1.3zm-21-2h20v-2h-20v2zM131.9 165a.9.9 0 01-.7-.4.9.9 0 01.2-1.3l19-14.1.4-.2H174c.2 0 .4 0 .5.2l18.2 14.2c.4.3.5.8.2 1.2-.3.4-1.8.5-2.2.2l-17-13h-22.5l-17.7 13-1.5.2z"
            fill={color}
          />
          <g>
            <path
              d="M148 142a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z"
              fill={color}
            />
            <path
              d="M148 136a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M177 142a4 4 0 110-8 4 4 0 010 8zm0-6a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z"
              fill={color}
            />
            <path
              d="M177 136a2 2 0 00-2 2c0 1.1.9 2 2 2a2 2 0 002-2 2 2 0 00-2-2z"
              fill="#fff"
            />
          </g>
          <g>
            <path
              d="M167.8 112.7h-10.7c-2.3 0-4.1-1.9-4.1-4.2v-2.3c0-2.3 1.8-4.2 4-4.2h10.8c2.3 0 4.1 1.9 4.1 4.2v2.3c0 2.3-1.8 4.2-4 4.2zm-9.6-7.8c-1.1 0-2 .8-2 1.7v1.6c0 1 .9 1.7 2 1.7h9c1.1 0 2-.7 2-1.7v-1.6c0-1-.9-1.7-2-1.7h-9z"
              fill={color}
            />
            <path
              d="M158.2 105c-1.1 0-2 .7-2 1.6v1.6c0 1 .9 1.7 2 1.7h9c1.1 0 2-.7 2-1.7v-1.6c0-1-.9-1.7-2-1.7h-9z"
              fill={color}
              fillOpacity="0.8"
            />
          </g>
        </g>
        <g fillRule="nonzero" mask="url(#b)">
          <path
            d="M112 166H44.1v-34.3c0-18.6 15-33.7 33.7-33.7h.6C97 98 112 113 112 131.7v34.2z"
            fill="#fff"
          />
          <path
            d="M113 166.8H43.1v-35.1c0-19 15.5-34.6 34.6-34.6h.6c19 0 34.5 15.5 34.5 34.6v35.1zM46 164h64v-32.3a31.7 31.7 0 00-31.6-31.7h-.7a31.7 31.7 0 00-31.6 31.7v32.3z"
            fill={color}
          />
          <path
            fill={color}
            d="M55.6 165.6l5-9.7 2.8 1.1-5.1 9.7zM93.7 157.3l3-1.4 5.4 9.6-3 1.5z"
          />
          <g>
            <path
              d="M94.5 158h-33c-4.7 0-7.5-2-7.5-5.6l3.6-38.5a7 7 0 011.6-4.5c1-1.1 2.8-2.4 6-2.4h25.6c3.2 0 5 1.3 5.9 2.4 1 1.2 1.6 2.8 1.7 4.5l3.6 38.4c0 1.6-.4 2.8-1.3 3.7-1.6 1.7-4.3 2-6.2 2zM57 151.4c0 2.4 1.8 3.6 5.4 3.6h31.2c2.1 0 3.8-.5 4.6-1.3.6-.6.8-1.3.8-2.3l-3.5-36.5a5 5 0 00-1.1-3.3c-1-1-2.4-1.6-4.2-1.6H65.8c-1.8 0-3.3.5-4.2 1.6a5 5 0 00-1.1 3.3L57 151.4z"
              fill={color}
            />
            <path
              d="M90 158H67c-.6 0-1-.5-1-1v-2.6c0-3 2.2-5.4 5-5.4h15c2.8 0 5 2.4 5 5.4v2.5c0 .6-.4 1.1-1 1.1zm-21-3h19v-1c0-1-1.2-2-2.7-2H71.7c-1.5 0-2.7 1-2.7 2v1z"
              fill={color}
            />
            <path
              d="M69 155h19v-1c0-1-1.2-2-2.7-2H71.7c-1.5 0-2.7 1-2.7 2v1z"
              fill={color}
              fillOpacity="0.8"
            />
            <path
              d="M90 139H66c-1.7 0-3-.5-3.8-1.4-1.4-1.5-1.3-3.8-1-5.7l1.4-14.5c.3-3.1 2.6-5.4 5.3-5.4h20.3c2.6 0 4.9 2.3 5.2 5.3L95 132c.3 2.4-.1 4.4-1.3 5.6-.9 1-2.1 1.5-3.7 1.5zm-21.3-24c-1.6 0-3 1.5-3.3 3.4l-1.3 13c-.2 2 0 3.2.6 3.8.4.5 1.3.8 2.4.8H89c1 0 1.7-.3 2.2-.8.8-.8 1-2.1.8-3.8l-1.3-13c-.2-2-1.7-3.4-3.3-3.4H68.7z"
              fill={color}
            />
            <path
              d="M68.7 115c-1.6 0-3 1.5-3.3 3.4l-1.3 13c-.2 2 0 3.2.6 3.8.4.5 1.3.8 2.4.8H89c1 0 1.7-.3 2.2-.8.8-.8 1-2.1.8-3.8l-1.3-13c-.2-2-1.7-3.4-3.3-3.4H68.7z"
              fill={light}
            />
            <path
              d="M93.2 133H62.8c-.4 0-.8-.4-.8-1s.4-1 .8-1h30.4c.4 0 .8.4.8 1s-.4 1-.8 1zM92.2 120H63.8c-.4 0-.8-.4-.8-1s.4-1 .8-1h28.4c.4 0 .8.4.8 1s-.4 1-.8 1zM65.6 147h-2.2c-1.9 0-3.4-1.3-3.4-3s1.5-3 3.4-3h2.2c1.9 0 3.4 1.3 3.4 3s-1.5 3-3.4 3zm-2.2-4.4c-.9 0-1.6.6-1.6 1.4 0 .8.8 1.4 1.6 1.4h2.2c.9 0 1.6-.7 1.6-1.4 0-.8-.8-1.4-1.6-1.4h-2.2zM92.6 147h-2.2c-1.9 0-3.4-1.3-3.4-3s1.5-3 3.4-3h2.2c1.9 0 3.4 1.3 3.4 3s-1.5 3-3.4 3zm-2.2-4.4c-.9 0-1.6.6-1.6 1.4 0 .8.8 1.4 1.6 1.4h2.2c.9 0 1.6-.7 1.6-1.4 0-.8-.8-1.4-1.6-1.4h-2.2z"
              fill={color}
            />
          </g>
        </g>
        <g mask="url(#b)">
          <g fillRule="nonzero">
            <path
              d="M126.1 74.4c0 2.3 1.7 4.2 3.9 4.2 2.1 0 3.8-1.9 3.8-4.2h-7.7z"
              fill={color}
              fillOpacity="0.8"
            />
            <path
              d="M130 79.8c-2.8 0-5-2.4-5-5.4 0-.6.5-1.2 1.1-1.2.6 0 1 .6 1 1.2 0 1.7 1.3 3 2.8 3 1.6 0 2.8-1.3 2.8-3 0-.6.5-1.2 1-1.2.7 0 1.2.6 1.2 1.2 0 3-2.2 5.4-5 5.4z"
              fill={color}
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M80.3 74.4c0 2.3 1.7 4.2 3.7 4.2s3.8-1.9 3.8-4.2h-7.5z"
              fill={color}
              fillOpacity="0.8"
            />
            <path
              d="M84 79.8c-2.6 0-4.8-2.4-4.8-5.4 0-.6.5-1.2 1-1.2.7 0 1.1.6 1.1 1.2 0 1.7 1.2 3 2.7 3 1.5 0 2.7-1.3 2.7-3 0-.6.4-1.2 1-1.2s1 .6 1 1.2c.1 3-2 5.4-4.7 5.4z"
              fill={color}
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M80.8 53.3c-1-.7-1.7-1.9-1.7-3.2 0-2.1 1.7-3.8 3.7-3.8.8 0 1.6.3 2.2.7l45.9.6c.6-.5 1.3-.8 2.1-.8 2 0 3.7 1.7 3.7 3.8 0 1.3-.6 2.5-1.6 3.2l-54.3-.5z"
              fill={color}
              fillOpacity="0.8"
            />
            <path
              d="M135 54.8l-54.3-.5a1 1 0 01-.5-.2 5 5 0 01-2.1-4c0-2.7 2-5 4.7-5 .9 0 1.8.3 2.5.8l45.2.5c.8-.5 1.6-.7 2.5-.7 2.6 0 4.8 2.1 4.8 4.9a5 5 0 01-2.1 4c-.2.2-.4.2-.6.2zm-54-2.6l53.7.5c.6-.5 1-1.3 1-2.1 0-1.5-1.2-2.8-2.7-2.8-.5 0-1 .2-1.5.6a1 1 0 01-.7.2L85 48c-.2 0-.4 0-.6-.2-.5-.3-1-.5-1.6-.5a2.7 2.7 0 00-2.6 2.7c0 .8.3 1.6.9 2z"
              fill={color}
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M129.8 52.8L128 41.6a7 7 0 00-7-5.8H95.4a7.1 7.1 0 00-7 5.4l-2.6 11.6h44z"
              fill="#fff"
            />
            <path
              d="M84 53h-.2a1 1 0 01-.8-1.2l2.8-12.3a8.6 8.6 0 018.5-6.5h27.3c4.3 0 8 3 8.6 7L132 52c0 .5-.3 1-.9 1-.6.2-2-.2-2.2-.7l-1.8-11.8c-.5-3.1-2.2-4.4-5.5-4.4H94.3c-3 0-4.8 1-5.4 4L86 52.1c0 .5-1.5.8-2 .8z"
              fill={color}
            />
          </g>
          <path
            d="M139 76H76a1 1 0 01-1-1V61.2c0-5 4.3-9.2 9.6-9.2h45.8c5.3 0 9.6 4.1 9.6 9.2V75c0 .5-.5 1-1 1zm-61-3h59V61.5c0-3.6-3.2-6.5-7.3-6.5H85.3c-4 0-7.3 2.9-7.3 6.5V73z"
            fill={color}
            fillRule="nonzero"
          />
          <g fillRule="nonzero">
            <path
              fill={color}
              fillOpacity="0.8"
              d="M107.7 66h8.1V63H99.2V66h5.3z"
            />
            <path
              d="M115.8 67H99.2a1 1 0 01-1-1V63c0-.6.4-1 1-1h16.6c.6 0 1.1.4 1.1 1V66c0 .6-.5 1.1-1 1.1zm-15.5-2.2h14.5v-.6h-14.5v.6zM115.8 32H99.2a1 1 0 01-1-1V28c0-.6.4-1 1-1h16.6c.6 0 1.1.4 1.1 1V31c0 .6-.5 1.1-1 1.1zm-15.5-2.2h14.5v-.6h-14.5v.6z"
              fill={color}
            />
          </g>
          <g fillRule="nonzero">
            <path fill="#fff" d="M76.2 71H139z" />
            <path
              d="M139 71H76.2c-.6 0-1-.5-1-1s.4-1 1-1H139c.5 0 1 .5 1 1s-.5 1-1 1z"
              fill={color}
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M124.2 61.9h10.6c0-2.6-2-4.7-4.5-4.7h-6.1V62z"
              fill="#fff"
            />
            <path
              d="M134.9 63h-10.7a1 1 0 01-1-1.1v-4.7c0-.5.5-1 1-1h6.3c3 0 5.4 2.5 5.4 5.6v.1c0 .6-.5 1-1 1zm-9.7-2.2h8.5a3.4 3.4 0 00-3.3-2.5h-5.2v2.5z"
              fill={color}
            />
          </g>
          <g fillRule="nonzero">
            <path d="M90.8 61.9H80.2c0-2.6 2-4.7 4.5-4.7H91V62z" fill="#fff" />
            <path
              d="M90.8 63H80.2a1 1 0 01-1-1.1v-.1c0-3 2.4-5.6 5.4-5.6H91c.5 0 1 .4 1 1V62c0 .6-.5 1-1 1zm-9.4-2.2h8.4v-2.5h-5.2a3.4 3.4 0 00-3.2 2.5z"
              fill={color}
            />
          </g>
        </g>
        <g
          mask="url(#b)"
          stroke={color}
          strokeDasharray="7,10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        >
          <path d="M59.7 181.5a86.5 86.5 0 00112.6-9.4M79.5 30.7a86.5 86.5 0 00-49.8 111.8M194.8 131A86.5 86.5 0 00130 27.2" />
        </g>
      </g>
    </svg>
  );
}
