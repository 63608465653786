import { AppLoader } from "common/AppLoader";
import { PageBanner } from "common/banner/PageBanner";
import { MobeaButton } from "common/forms";
import { useBudget, useCustomerData } from "common/hooks";
import { useAppName } from "common/hooks/useAppName";
import { useClientConfig } from "common/hooks/useClientConfig";
import { useClientLogo } from "common/hooks/useClientLogo";
import { Spinner } from "common/Spinner";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateCustomer } from "services/customerService";
import { welcomeDoneAction } from "state/actions";
import { getLanguages, pushLocation, Routes } from "utils";
import i18n from "utils/i18n";
import { LanguageSelector, SelectorInputLanguage } from "./LanguageSelector";
import "./WelcomePage.scss";

export function WelcomePage(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [updatingClientConfig] = useClientConfig();

  const { customerId, language } = useSelector((state) => state.user);

  const loading = useCustomerData();

  const clientLogo = useClientLogo();

  const appName = useAppName();

  useBudget();

  const onStartClicked = () => {
    dispatch(welcomeDoneAction());
    pushLocation(history, Routes.Default);
  };

  const onLanguageChanged = (lang: string) => {
    i18n.changeLanguage(lang);

    updateCustomer(
      customerId,
      {
        language: lang,
      },
      history
    );
  };

  const languages: SelectorInputLanguage[] = Object.entries(getLanguages()).map(
    (lang) => ({
      code: lang[0],
      // t("languages.dutch")
      // t("languages.english")
      // t("languages.french")
      title: t(`languages.${lang[1].toLowerCase()}`),
    })
  );

  return (
    <main className="mobea-welcome">
      {updatingClientConfig && <AppLoader />}

      {loading && <Spinner />}

      {!loading && !updatingClientConfig && (
        <>
          <div className="mobea-welcome__start__wrapper">
            <section className="mobea-welcome__start">
              <div className="mobea-welcome__start__brand">{clientLogo}</div>
              <PageBanner>
                {t("welcome.welcome_to")}
                <br />
                {appName}!
              </PageBanner>
              <p className="mobea-welcome__start__text">
                {t("welcome.welcome_text")}
              </p>
              <MobeaButton onClick={onStartClicked}>
                {t("welcome.lets_start")}
              </MobeaButton>
            </section>
          </div>

          <LanguageSelector
            languages={languages}
            currentLanguageCode={language}
            onChange={onLanguageChanged}
          />
        </>
      )}
    </main>
  );
}
