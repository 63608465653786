import { BottomNavBar, TabNavItem, TabPaneNav } from "common/navigation";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Routes, WalletType } from "utils";
import { HistoryExpensesList } from "./expenses/HistoryExpensesList";
import "./HistoryPage.scss";
import { HistoryTicketsList } from "./tickets/HistoryTicketsList";
import { HistoryTutorial } from "./tutorial/HistoryTutorial";

export function HistoryEaPage(): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const tab = searchParams.get("tab") || "tickets";

  const activeTab = tab === "tickets" ? 0 : 1;

  const historyTabs: TabNavItem[] = [
    { key: "tickets", label: t("history.tickets") },
    { key: "expenses", label: t("history.expenses") },
  ];

  const go = (tab?: number) => {
    const params = new URLSearchParams();

    params.set("tab", (tab ?? activeTab) === 0 ? "tickets" : "expenses");

    history.replace(Routes.History + "?" + params.toString());
  };

  const changeTab = (index: number) => {
    go(index);
  };

  return (
    <main className="mobea-history">
      <header className="mobea-history__header">
        <h1>{t("history.my_history")}</h1>

        <TabPaneNav
          tabs={historyTabs}
          active={activeTab}
          onTabChange={changeTab}
        />
      </header>

      <section className="mobea-history__tab-container">
        {activeTab === 0 && <HistoryTicketsList wallet={WalletType.BUSINESS} />}
        {activeTab === 1 && <HistoryExpensesList />}
      </section>

      <BottomNavBar />

      <HistoryTutorial />
    </main>
  );
}
