import { useBudget } from "common/hooks";
import { useAppName } from "common/hooks/useAppName";
import { MobeaModal } from "common/modal";
import { ReactElement, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeNoBudgetWarningAction,
  closeOutOfBudgetPeriodWarning,
} from "state/actions";
import { BUDGET_COOLDOWN_PERIOD_DAYS } from "utils";
import "./Warnings.scss";

export function GlobalWarning(): ReactElement {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    planLoaded,
    amount,
    outOfBudgetPeriod,
    noBudgetWarningClosed,
    outOfBudgetPeriodWarningClosed,
    remainingDays,
    startDate,
  } = useSelector((state) => state.user);

  const [, reloadBudget] = useBudget();

  const appName = useAppName();

  useEffect(() => {
    const intervalRef = window.setInterval(() => reloadBudget, 30 * 60 * 1000); // update every half an hour

    return () => {
      window.clearInterval(intervalRef);
    };
  }, [reloadBudget]);

  const closeNoBudget = () => dispatch(closeNoBudgetWarningAction());

  const closeOutOfBudgetPeriod = () =>
    dispatch(closeOutOfBudgetPeriodWarning());

  // remaining days are negative if after budget period
  const days = Math.min(
    Math.max(BUDGET_COOLDOWN_PERIOD_DAYS + remainingDays, 0),
    BUDGET_COOLDOWN_PERIOD_DAYS
  );

  // ignore if no start date is set
  const notStartedYet = startDate ? Date.now() < startDate : false;

  return (
    <>
      {planLoaded && amount <= 0 && !noBudgetWarningClosed && (
        <MobeaModal
          className="mobea__no_balance"
          type="error"
          title={t("warning.no_balance_headline")}
          confirmText="OK"
          onConfirm={closeNoBudget}
        >
          {t("warning.no_balance_text", { appName })}
        </MobeaModal>
      )}

      {planLoaded &&
        outOfBudgetPeriod &&
        !notStartedYet &&
        !outOfBudgetPeriodWarningClosed && (
          <MobeaModal
            className="mobea__out_of_budget_period"
            type="error"
            title={t("warning.budget_expired")}
            confirmText={t("shared.ok")}
            onConfirm={closeOutOfBudgetPeriod}
          >
            {
              <Trans
                i18nKey="warning.budget_expired_text"
                count={days}
                values={{ appName, days }}
              >
                <span className="mobea__bold mobea__arial">{{ days }}</span>.
              </Trans>
            }
          </MobeaModal>
        )}
    </>
  );
}
