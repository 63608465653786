import { trackPhoneVerification } from "analytics/events";
import { MobeaButton, ReactCodeInput } from "common/forms";
import { useRefreshOnReShow } from "common/hooks";
import { useClientLogo } from "common/hooks/useClientLogo";
import { CustomMobeaModal, MobeaModal, useContactUsModal } from "common/modal";
import { FullScreenPage } from "common/page/FullScreenPage";
import { CircleCheckIcon } from "icons/CircleCheckIcon";
import { MouseEvent, ReactElement, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "services/authTokenStore";
import { confirmCode } from "services/verificationService";
import { setCustomerIdAction, verificationFinishedAction } from "state/actions";
import { LoginResultEnum, MobeaError, pushLocation, Routes } from "utils";
import { getRedirectRoute } from "utils/routing";
import "./ConfirmPhoneVerificationPage.scss";

interface ConfirmPhoneVerificationError extends MobeaError {
  type: "invalid" | "external";
}

export function ConfirmPhoneVerificationPage(): ReactElement {
  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // to solve iOS freeze issue when checking for SMS (https://bugs.webkit.org/show_bug.cgi?id=211018)
  useRefreshOnReShow();

  const [code, setCode] = useState("");

  const [valid, setValid] = useState(false);

  const [pendingRequest, setPendingRequest] = useState(false);

  const [requestSuccess, setRequestSuccess] = useState(false);

  const [requestError, setRequestError] =
    useState<ConfirmPhoneVerificationError | null>(null);

  const clientLogo = useClientLogo();

  const codeInputRef = useRef<ReactCodeInput>(null);

  const { show: showContactUs } = useContactUsModal();

  const clearCode = () => {
    setCode("");
    codeInputRef.current?.__clearvalues__();
    setValid(false);
  };

  const verifyCode = async () => {
    setPendingRequest(true);

    try {
      const response = await confirmCode(code);

      batch(() => {
        setPendingRequest(false);

        if (response === LoginResultEnum.SUCCESS) {
          trackPhoneVerification("success");

          const customerId = Auth.getCustomerId();

          if (customerId) {
            dispatch(setCustomerIdAction(customerId));
          } else {
            console.error(
              "Error customer id not found after logging in!",
              Auth["token"]
            );
          }
          setRequestSuccess(true);
        } else {
          trackPhoneVerification("failure");

          setRequestError({
            type: "invalid",
            message: "Invalid Code",
            details: "",
          });
        }
      });
    } catch (error) {
      trackPhoneVerification("failure");

      batch(() => {
        setPendingRequest(false);
        setRequestError({
          type: "external",
          message: "Service error",
          details: error,
        });
      });
    }
  };

  const retry = () => {
    batch(() => {
      clearCode();
      setRequestError(null);
    });
  };

  const goBackToRequest = () => {
    dispatch(verificationFinishedAction());

    pushLocation(history, getRedirectRoute());
  };

  const goToHome = () => {
    dispatch(verificationFinishedAction());
    pushLocation(history, Routes.Home);
  };

  const backToRequestClick = (e: MouseEvent) => {
    e.preventDefault();
    goBackToRequest();
  };

  const openHelpDialog = () => {
    retry();
    showContactUs();
  };

  return (
    <FullScreenPage
      pageClassName="mobea-request-pin mobea-confirm-pin"
      padding={{
        left: 32,
        right: 32,
      }}
      css={{
        "> section": {
          justifyContent: "space-evenly",
        },
      }}
    >
      <header className="mobea-request-pin__header">{clientLogo}</header>
      <section className="mobea-request-pin__content">
        <h1 className="mobea-request-pin__content__headline">
          {t("verification.phone_verification")}
        </h1>
        <p className="mobea-request-pin__content__text">
          {t("verification.enter_code_text")}
        </p>
        <ReactCodeInput
          values={code.split("")}
          autoFocus
          type="tel"
          className="mobea-confirm-pin__code__input"
          fields={6}
          title=""
          fieldWidth={25}
          fieldHeight={50}
          ref={codeInputRef}
          onChange={(value) => {
            setCode(value);
            valid && setValid(false);
          }}
          onComplete={() => {
            setValid(true);
          }}
          onEnter={verifyCode}
        />
        <MobeaButton disabled={!valid} onClick={verifyCode}>
          {pendingRequest && t("verification.checking")}
          {!pendingRequest && t("verification.verify_code")}
        </MobeaButton>
        <p className="mobea-confirm-pin__no-code">
          <Link
            to={Routes.RequestPhoneVerification}
            onClick={backToRequestClick}
          >
            {t("verification.dont_receive_code")}
          </Link>
        </p>
      </section>
      {requestError && (
        <CustomMobeaModal
          className="mobea-confirm-pin__error-modal"
          title={t("shared.sorry_but")}
          type="error"
          withHeader
        >
          {requestError.type === "invalid" && (
            <p>{t("verification.error_invalid_code")}</p>
          )}
          {requestError.type === "external" && (
            <p>{t("verification.error_external")}</p>
          )}
          <footer>
            <MobeaButton
              className="mobea-confirm-pin__error-modal__retry"
              onClick={retry}
            >
              {t("verification.try_again")}
            </MobeaButton>
            <MobeaButton
              className="mobea-confirm-pin__error-modal__help"
              type="secondary"
              onClick={openHelpDialog}
            >
              {t("verification.need_help")}
            </MobeaButton>
          </footer>
        </CustomMobeaModal>
      )}
      {requestSuccess && (
        <MobeaModal
          confirmText={t("shared.super")}
          title={t("verification.phone_verified")}
          image={<CircleCheckIcon />}
          onConfirm={goToHome}
        >
          <p>{t("verification.phone_verified_text")}</p>
        </MobeaModal>
      )}
    </FullScreenPage>
  );
}
