import { CheckboxGroup, MobeaButton } from "common/forms";
import { MobeaModal } from "common/modal";
import { useTutorialSuspender } from "common/tutorial2/Tutorial";
import { UpIcon } from "icons/UpIcon";
import { ReactElement, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  advertisingChangeAction,
  analyticsChangeAction,
  cookiesAcceptAction,
} from "state/actions";
import { getCookiesPolicyLink, getPrivacyLink } from "utils";
import "./Cookies.scss";
import { CookiesDetailSection } from "./DetailsSection";

export function CookiesBanner(): ReactElement | null {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const language = useSelector((state) => state.user.language);

  const cookiesAccepted = useSelector(
    (state) => state.onboarding.cookiesAccepted
  );

  const [analytical, setAnalytical] = useState(false);

  const [advertising, setAdvertising] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  const acceptSelected = () => {
    dispatch(cookiesAcceptAction());
    dispatch(analyticsChangeAction(analytical));
    dispatch(advertisingChangeAction(advertising));
  };

  const acceptAll = () => {
    dispatch(cookiesAcceptAction());
    dispatch(analyticsChangeAction(true));
    dispatch(advertisingChangeAction(true));
  };

  const openCookies = !cookiesAccepted;

  useTutorialSuspender("cookies", openCookies);

  return openCookies ? (
    <MobeaModal
      className="mobea__cookies"
      title={t("cookies.cookies")}
      confirmButton={
        <section className="mobea__cookies__buttons">
          <MobeaButton
            type="primary"
            className="mobea__cookies__buttons__all"
            onClick={acceptAll}
          >
            {t("cookies.accept_all")}
          </MobeaButton>
          <MobeaButton
            type="secondary"
            className="mobea__cookies__buttons__selected"
            onClick={acceptSelected}
          >
            {t("cookies.accept_selected")}
          </MobeaButton>
        </section>
      }
    >
      <div className="mobea__cookies__text">
        <Trans i18nKey="cookies.cookies_text">
          Please set your cookie preferences below.Functional cookies are eeded
          for the proper functioning of the webapplication and cannot be
          refused.For more info read our
          <a
            className="mobea__cookies__text__link"
            href={getCookiesPolicyLink(language)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </a>
          and
          <a
            className="mobea__cookies__text__link"
            href={getPrivacyLink(language)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Trans>
      </div>
      <button
        className={`mobea__cookies__more mobea__${
          expanded ? "expanded" : "collapsed"
        }`}
        onClick={toggleExpand}
      >
        {expanded ? t("cookies.read_less") : t("cookies.read_more")}
        <UpIcon />
      </button>
      <hr className="mobea__cookies__separator" />
      <form className="mobea__cookies__checkboxes">
        <CheckboxGroup
          label={t("cookies.functional")}
          name="functional"
          isChecked
          disabled
          onChange={() => undefined}
        />
        <CheckboxGroup
          label={t("cookies.analytical")}
          name="analytical"
          isChecked={analytical}
          onChange={setAnalytical}
        />
        <CheckboxGroup
          label={t("cookies.advertising")}
          name="advertising"
          isChecked={advertising}
          onChange={setAdvertising}
        />
      </form>
      <hr className="mobea__cookies__separator" />
      {expanded && (
        <section className="mobea__cookies__details">
          {/* t("cookies.functional_title") */}
          {/* t("cookies.functional_text") */}
          <CookiesDetailSection kind="functional" />

          {/* t("cookies.analytical_title") */}
          {/* t("cookies.analytical_text") */}
          <CookiesDetailSection kind="analytical" />

          {/* t("cookies.advertising_title") */}
          {/* t("cookies.advertising_text") */}
          <CookiesDetailSection kind="advertising" />
        </section>
      )}
    </MobeaModal>
  ) : null;
}
