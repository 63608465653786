import { ReactElement } from "react";
import { DefaultIconProps } from "./props";

export function TickIcon({
  fill = "currentColor",
  className = "",
  height = "12",
  width = "10",
}: DefaultIconProps): ReactElement {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.808 10L12 1.049 11.072 0 3.808 7.937.96 4.79 0 5.84z"
        fill={fill}
      />
    </svg>
  );
}
